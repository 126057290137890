import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Routes } from "react-router";
import { BrowserRouter, Navigate, Route } from "react-router-dom";

// import "./App.css";
import Layout from "./Components/Layout";
import Logout from "./Components/Logout";
import Challenge from "./Pages/Challenge";
import Edit from "./Pages/Edit";
import EventDetails from "./Pages/EventDetails";
import EventPlay from "./Pages/EventPlay";
import Events from "./Pages/Events";
import ExamPlay from "./Pages/ExamPlay";
import Faq from "./Pages/Faq";
import ForgetPassword from './Pages/ForgetPassword';
import Home from "./Pages/HomePage";
import Login from "./Pages/Login";
import MyResult from "./Pages/MyResult";
import PasswordReset from './Pages/PasswordReset';
import Prize from "./Pages/Prize";
import Profile from "./Pages/Profile";
import Result from "./Pages/Result";
import Rule from "./Pages/Rule";
import SubmitOtp from './Pages/SubmitOtp';
import UserLogin from "./Pages/UserLogin";
import UserRegistration from "./Pages/UserRegistration";
import VerifyEmail from "./Pages/VerifyEmail";
import { authOperations } from "./state/ducs/auth";


import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import About from "./Pages/AboutUs";

const MySwal = withReactContent(Swal);


function App({ checkAuth, isAuthenticated, error }) {
  const [login, setlogin] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [golobalKeyword, setgolobalKeyword] = useState("");

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (error && error.length) {
      MySwal.fire(<p>{error}</p>)
    }
  }, [error])


  console.log(isAuthenticated);

  let routes = (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            setgolobalKeyword={setgolobalKeyword}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            t={t}
            login={login}
            setlogin={setlogin}
          />
        }
      />
      <Route path="/rule" element={<Rule />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/about" element={<About />} />
      <Route path="/prize" element={<Prize />} />
      <Route path="/result" element={<Result />} />
      <Route path="/events" element={<Events />} />

      {/* <Route path="/productDetails/:productId" element={<ProdectDetails />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/viewPage" element={<ViewPage />} />
      <Route
        path="/categoryDetails/:categoryId"
        element={<CategoryDetails t={t} />}
      /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/user-login" element={<UserLogin />} />
      <Route path="/user-register" element={<UserRegistration />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="/submit-otp" element={<SubmitOtp />} />
      {/* <Route path="/forgottenPassword" element={<ForgottenPassword />} />
      <Route path="/register" element={<Register setlogin={setlogin} />} />
      <Route
        path="/search"
        element={
          <Search
            golobalKeyword={golobalKeyword}
            searchKeyword={searchKeyword}
            t={t}
          />
        }
      /> */}

      <Route path="*" element={<Navigate to="/user-login" />} />
    </Routes>
  );

  if (isAuthenticated) {
    routes = (
      <Routes>
        <Route
          path="/"
          element={
            <Home
              setgolobalKeyword={setgolobalKeyword}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              t={t}
              login={login}
              setlogin={setlogin}
            />
          }
        />

        <Route path="/rule" element={<Rule />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/prize" element={<Prize />} />
        <Route path="/result" element={<Result />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/events" element={<Events />} />
        <Route path="/myResult" element={<MyResult />} />
        <Route path="/edit" element={<Edit/>} />
        <Route path="/about" element={<About />} />

        <Route
          path="/eventDetails/:eventId"
          element={<EventDetails />}
        />

        <Route
          path="/eventPlay/:eventId"
          element={<EventPlay />}
        />

        <Route
          path="/examPlay/:eventId"
          element={<ExamPlay />}
        />

        <Route path="/varifyEmail/:email/:token" element={<VerifyEmail />} />

        <Route path="/challenge/:event_id" element={<Challenge />} />

        {/* <Route path="/productDetails/:productId" element={<ProdectDetails />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/viewPage" element={<ViewPage />} />
        <Route
          path="/categoryDetails/:categoryId"
          element={<CategoryDetails t={t} />}
        /> */}

        {/* <Route path="/forgottenPassword" element={<ForgottenPassword />} />
        <Route path="/register" element={<Register setlogin={setlogin} />} />
        <Route path="/addressBook" element={<AddressBook />} />
        <Route path="/myAccounts" element={<MyAccounts />} />
        <Route path="/newslatter" element={<Newslatter />} />
        <Route path="/wishList" element={<WishList />} />
        <Route
          path="/search"
          element={
            <Search
              golobalKeyword={golobalKeyword}
              searchKeyword={searchKeyword}
              t={t}
            />
          }
        /> */}

        <Route path="/user-login" element={<UserLogin />} />

        <Route path="/user-register" element={<UserRegistration />} />


        <Route path="/logout" element={<Logout />} />

        <Route path="*" element={<Navigate to="/user-login" />} />
      </Routes>
    );
  }

  return (
    <div>
      <BrowserRouter>
        <Layout
          login={login}
          setlogin={setlogin}
          t={t}
          changeLn={changeLanguage}
        >
          {routes}
        </Layout>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    error: state.auth.error
  };
};

const mapDispatchToProps = {
  checkAuth: authOperations.authStateCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
