import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import "./index.css";

import { authOperations } from "../../../state/ducs/auth";

const Header = ({
  loading,
  error,
  isAuthenticated,
  authRedirectPath,
  signIn,
  setAuthRedirectPath,
  userInfo,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    console.log(location.pathname);
  }, []);

  const goToProfile = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/profile");
  };

  const goToHome = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/");
  };

  const gotoRule = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/rule");
  };

  const gotoResult = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/result");
  };

  const goToFaq = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/faq");
  };

  const goToPrize = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/prize");
  };

  const goToabout = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/about");
  };

  const logOut = (e) => {
    e.stopPropagation();
    navigate("/logout");
  };

  const goToLogin = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/user-login");
  };

  const goToRegister = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/user-register");
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);

  return (
    <nav className={`navbar navbar-expand-lg ${scrolled ? "scrolled" : ""}`}>
      <div class="container h-100">
        <a onClick={goToHome} class="navbar-brand h-100" href="#">
          <img
           height="80px"
           className="emon h-100"
            src="https://bcs-confidence.prostuti.net/admin/media/logo/logo-63a43964406bc.png"
            alt=""
          />
        </a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav m-auto">
            <li class="nav-item">
              <a
                onClick={goToHome}
                class={`nav-link ${location.pathname == "/" ? "active" : ""}`}
                aria-current="page"
                href="/"
              >
                হোম{" "}
              </a>
            </li>
            <li class="nav-item">
              <a
                onClick={gotoRule}
                class={`nav-link ${
                  location.pathname == "/rule" ? "active" : ""
                }`}
                aria-current="page"
                href="/rule"
              >
                নিয়মাবলী
              </a>
            </li>
            <li class="nav-item">
              <a
                onClick={gotoResult}
                class={`nav-link ${
                  location.pathname == "/result" ? "active" : ""
                }`}
                aria-current="page"
                href="/result"
              >
                ফলাফল
              </a>
            </li>
            <li class="nav-item">
              <a
                onClick={goToFaq}
                class={`nav-link ${
                  location.pathname == "/faq" ? "active" : ""
                }`}
                aria-current="page"
                href="/faq"
              >
                FAQ{" "}
              </a>
            </li>

            <li class="nav-item">
              <a
                onClick={goToPrize}
                class={`nav-link ${
                  location.pathname == "/prize" ? "active" : ""
                }`}
                aria-current="page"
                href="/prize"
              >
                {" "}
                পুরস্কার{" "}
              </a>
            </li>
          </ul>

          {isAuthenticated && (
            <>
              <form className="d-flex">
                <div
                  className="profile-avatar"
                  onClick={goToProfile}
                  href="/profile"
                >
                  {userInfo.full_user_photo_path ? (
                    <img
                      role="button"
                      className="rounded-circle cursor-pointer"
                      height={"70"}
                      width={"70"}
                      src={userInfo.full_user_photo_path}
                      alt=""
                    />
                  ) : (
                    <span role="button">
                      <i
                        className="fa fa-user"
                        style={{ fontSize: "30px" }}
                      ></i>
                    </span>
                  )}

                  <div className="logoutButton" style={{ marginLeft: "2px" }}>
                    <div className="item" onClick={logOut}>
                      Logout
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}

          {!isAuthenticated && (
            <>
              <form class="d-flex">
                <a onClick={goToLogin} href="/user-login">Login</a>
              </form>

              <form class="d-flex" style={{ marginLeft: "2px" }}>
                <a onClick={goToRegister} href="/user-register">Registration</a>
              </form>
            </>
          )}
        </div>
        <div class="mobile_menu">
          <button
            class="btn"
            type="button"
            onClick={handleShow}
            aria-controls="offcanvasExample"
          >
            <img
              src="http://bcs-confidence.prostuti.net/admin/frontend/images/menu.png"
              alt=""
            />
          </button>

          <Offcanvas
            show={show}
            onHide={handleClose}
            responsive="lg"
            placement={"end"}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>MENU</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div class="navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                  <li class="nav-item">
                    <a
                      onClick={goToHome}
                      class={`nav-link ${
                        location.pathname == "/" ? "active" : ""
                      }`}
                      aria-current="page"
                      href="/"
                    >
                      হোম{" "}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      onClick={gotoRule}
                      class={`nav-link ${
                        location.pathname == "/rule" ? "active" : ""
                      }`}
                      aria-current="rule"
                      href="/rule"
                    >
                      নিয়মাবলী
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      onClick={gotoResult}
                      class={`nav-link ${
                        location.pathname == "/result" ? "active" : ""
                      }`}
                      aria-current="page"
                      href="/result"
                    >
                      ফলাফল
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      onClick={goToPrize}
                      class={`nav-link ${
                        location.pathname == "/prize" ? "active" : ""
                      }`}
                      aria-current="page"
                      href="/prize"
                    >
                      {" "}
                      পুরস্কার{" "}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link "
                      aria-current="page"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      যোগাযোগ
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      onClick={goToFaq}
                      class={`nav-link ${
                        location.pathname == "/faq" ? "active" : ""
                      }`}
                      aria-current="page"
                      href="/faq"
                    >
                      FAQ{" "}
                    </a>
                  </li>
                </ul>

                {isAuthenticated && (
                  <>
                    <form className="d-flex">
                      <div onClick={goToProfile} href="/profile">
                        {userInfo.full_user_photo_path ? (
                          <img
                            className="rounded-circle"
                            height={"70"}
                            width={"70"}
                            src={userInfo.full_user_photo_path}
                            alt=""
                          />
                        ) : (
                          <span>
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "30px" }}
                            ></i>
                          </span>
                        )}
                      </div>
                    </form>

                    <form className="d-flex" style={{ marginTop: "10px" }}>
                      <a onClick={logOut} href="/logout">Logout</a>
                    </form>
                  </>
                )}

                {!isAuthenticated && (
                  <>
                    <form class="d-flex">
                      <a onClick={goToLogin} href="/user-login">Login</a>
                    </form>

                    <form class="d-flex" style={{ marginTop: "2px" }}>
                      <a onClick={goToRegister} href="/user-register">Registration</a>
                    </form>
                  </>
                )}

                <div class="copy_right">
                  <p>
                    &copy; Prostuti All Rights Reserved <br /> Powered by
                    TechKnowGram Limited.
                  </p>
                </div>
                {/* <div className="appbtn">
                                    <a class="btn btn-dark" href="http://quizwin.xyz/admin/uploads/quizbuz.apk">
                                        <i class="fa fa-download mx-2" aria-hidden="true"></i>
                                        Download App
                                    </a>
                                </div> */}
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </nav>
  );
};

// const mapStateToProps = (state) => {
//     return { cartItems: state.cart };
// };

// const mapDispatchToProps = {
//     removeFromCart: cartOperations.removeFromCart,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Header);

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = {
  signIn: authOperations.auth,
  setAuthRedirectPath: authOperations.setAuthRedirectPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
